@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Orbitron:wght@400..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
@layer base {
  :root {
    --ring: hsl(212.7, 26.8%, 83.9);
  }
}

.custom-input:-webkit-autofill,
.custom-input:-webkit-autofill:hover,
.custom-input:-webkit-autofill:focus,
.custom-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #dedf1b inset; /* Change #f3f4f6 to your desired background color */
  box-shadow: 0 0 0px 1000px #dedf1b inset; /* Standard syntax; might not be necessary but included for completeness */
  border: 1px solid #dedf1b; /* Example border color */
}
/* Set core body defaults */
body {
  min-height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* styles.css */
#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value (0.5) to control the darkness */
}

/* Ensure the parent of the overlay has a relative positioning if not already positioned. */
.relative-positioning {
  position: relative;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --background-color: black;
  --white: #ffffff;
  --tw-color1: #a855f7;
  --tw-color1-hover: #9333ea;
}

body {
  font-family: "Inter", sans-serif !important;
  color: var(--white);
  margin: 0;
  text-align: center;
  background: #131313 url("/public/bg.png") center / cover no-repeat fixed !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Orbitron", sans-serif;
}

/* Wallet CSS */

.css-1ocvkcr {
  font-family: "Inter", sans-serif !important;

  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;

  font-size: 12px !important;
  font-weight: 600;
  height: 40px !important;
  line-height: 48px;
  padding: 4px 14px;
  border-radius: 10px !important;
  color: white !important;
  border: 1px solid #dedf1b !important;
  background-color: #1c1c1c !important;
}

.css-1ocvkcr:not([disabled]):focus-visible {
  outline-color: white;
}

.css-1ocvkcr:not([disabled]):hover {
  background-color: black;
}

.css-1ocvkcr[disabled] {
  background: #1c1c1c;
  color: #999;
  cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-adapter-dropdown-list {
  font-family: "Inter", sans-serif !important;

  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: #1c1c1c !important;
  border-radius: 10px;
  border: 1px solid #f2f189;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: #fff;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: black;
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active
  svg {
  transform: rotate(180deg);
  transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 12px;
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: white;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #10141f;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);

  flex: 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 64px 48px 48px 48px;
  text-align: center;
  color: #fff;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  font-weight: 400;
  border-radius: 0;
  font-size: 18px;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  margin-left: auto;
  font-size: 14px;
  opacity: 0.6;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  border: none;
  padding: 12px 24px 24px 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #fff;
}

.wallet-adapter-modal-list-more svg {
  transition: all 0.1s ease;
  fill: rgba(255, 255, 255, 1);
  margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
  display: block;
  cursor: pointer;
  margin-top: 48px;
  width: 100%;
  background-color: #512da8;
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  color: #fff;
}
/* components/GrainyBackground.css */

/** HEADER **/

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9d9d2c;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dedf1b;
  border-radius: 12px;
}
.grained {
  position: fixed; /* or 'absolute' */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 4; /* Ensures it stays behind other content */
  color: white !important;
}
label:has(+ input[required]):after,
label[data-required="true"]:after {
  color: #dedf1b;
  margin-left: 2px;
  content: "*";
}

input[type="datetime-local" i] {
  color: black !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
  background-color: #6e717a;
}
textarea:focus,
input:focus {
  outline: none !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  color: black !important;
  -webkit-appearance: none !important;
  background-color: none !important;
  margin: 0 !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*checking s*/

/* NAVBAR */

/** HEADER **/

/* HEADER CSS */
.nav-bg {
  background-color: black;
  margin-left: 3px;
  padding: 2px 0;
}
.logo-link {
  display: flex;
  gap: 12px; /* space-x-3 */
}

.logo-link span {
  font-size: 1.5rem; /* text-2xl */
  font-weight: 600; /* font-semibold */
  white-space: nowrap;
  color: #fff; /* dark:text-white */
}
.menu-item {
  display: flex;
  gap: 0.2rem;
  padding: 2px 13px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and color */
}
.menu-item a {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;

  color: #d6d6d6;
}

.menu-wrapper {
  position: relative;
}

.submenu::before {
  content: "";
  position: absolute;
  top: -6px;
  left: var(--arrow-position, 10%);
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #1c1c1c;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-radius: 2px;
}
.submenu > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-item {
}
.menu-item:hover,
.menu-item.active {
  background-color: #131313;
  color: #dedf1b !important;
}
.menu-item-wrapper {
  /* Add any necessary styles here, like display: inline-block or similar */
  display: inline-block;
}

.menu-item.active {
  padding: 2px 13px;
  border-radius: 8px;
  border-bottom: 0px solid black !important;
  background-color: #131313;
  color: #dedf1b;
}
.menu-wrapper .menu-item.active a,
.menu-wrapper .menu-item:hover a {
  color: #dedf1b; /* Ensure this applies to the <a> tag as well */
}

.submenu a {
  color: white;
  padding: 2px 13px;
}
.submenu {
  background-color: #131313;
  border: 1px solid #34341e !important;
  border-radius: 10px;
}
.submenu div:hover a {
  padding: 2px 13px;
  border-radius: 8px;
  background-color: black;
  color: #dedf1b;
}

/* Default state for mobile */
@media screen and (max-width: 767px) {
  .sub_info {
    display: none;
  }
  .my_sidebar {
    display: block;
  }
  .connect_button {
    margin-right: 25px;
  }
  .mobile_menu {
    display: flex;
  }
  .mobile_menu a {
    color: black !important;
  }
  .menu-list {
    display: flex;
    gap: 50px;
    padding: 0;
    margin: 0 20px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px; /* max-w-screen-xl */
    gap: 1px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
  }
  .menu-wrapper {
    display: none;
  }
  .nav-bg {
    display: flex !important;
    flex-direction: column !important;
  }
  .menu-list {
    display: none;
    flex-direction: column;
  }
  .mobile_menu-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
    margin: 0 auto;
    position: absolute;
    /* bottom: 0; */
    top: 30px;
    background-color: black !important;
    z-index: 9999999999999;
    /* right: 0; */
    left: 90px;
    padding: 0 20px;
  }
  .menu-toggle {
    display: block;
    position: absolute;
    padding: 4%;
    right: 4px;
    top: 5px;
    margin: auto;
  }

  .submenu {
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 100%; /* Add this line */
    padding: 0;
  }
}

/* Override when mobile-visible is active */
.mobile-visible {
  display: block; /* Show menu when this class is added */
  position: relative;
}
.desktop-visible {
  display: none; /* Show menu when this class is added */
}

/* Styles for larger screens */
@media screen and (min-width: 768px) {
  .sub_info {
    font-size: 13px;
    color: #a4a4a4;
  }
  .mobile_menu-list {
    display: none;
  }
  .menu-list {
    display: flex;
    gap: 10px;
    padding: 0;
    margin: auto;
  }
  .desktop-visible {
    display: block; /* Show menu when this class is added */
  }
  .nav-bg {
    display: block;
  }
  .menu-wrapper {
    display: block; /* Menu is always visible on desktop */
  }
  .menu-toggle {
    display: none; /* Hide toggle button on larger screens */
  }

  .submenu {
    display: flex; /* or 'flex' depending on your layout */
    /* Remove position, height, left, width properties if they conflict */
    justify-content: space-around;
    gap: 10px;
    height: 100%; /* Add this line */
    padding: 0;
  }
  .submenu_block {
    width: 580px;
    font-size: 13px;
    color: white;
    height: 170px;
    padding: 0;
    margin: 0 auto;
    background-color: black;
    border-radius: 0.5rem; /* rounded-lg */
    position: absolute;
    left: 5;
    margin-top: 0px; /* Adjust the value as needed */

    z-index: 100; /* Example z-index to ensure it's above other content */
    display: flex; /* Use flexbox for horizontal alignment */
    justify-content: space-around; /* Center-align items horizontally */
    align-items: center; /* Add this line */
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1580px; /* max-w-screen-xl */
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  /*APP MENU */

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1580px; /* max-w-screen-xl */
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .app_menu {
    width: 140px;
    height: 170px;
    padding: 0;
    margin: 0 auto;
    border-radius: 10px; /* rounded-lg */
    position: absolute;
    left: -50px;
    right: 0;
    margin-top: 0px; /* Adjust the value as needed */
    padding: 2px 13px;
    background-color: black;
    z-index: 100; /* Example z-index to ensure it's above other content */
    display: flex; /* Use flexbox for horizontal alignment */
    justify-content: space-around; /* Center-align items horizontally */
    align-items: center; /* Add this line */
    flex-direction: column;
  }
  .app_submenu {
    display: flex; /* or 'flex' depending on your layout */
    /* Remove position, height, left, width properties if they conflict */
    justify-content: space-around;
    gap: 30px;
    padding: 2px 13px;
    margin: auto 0;
    height: 100%; /* Add this line */
  }

  .app_submenu_block {
    width: 526px;
    font-size: 13px;
    background-color: #131313;
    border: 1px solid #34341e !important;
    border-radius: 10px;
    height: 150px;
    padding: 2px 2px;
    margin: 0 auto;
    position: absolute;
    left: 125px;
    margin-top: -30px; /* Adjust the value as needed */
    color: white;
    z-index: 100; /* Example z-index to ensure it's above other content */
    display: flex; /* Use flexbox for horizontal alignment */
    justify-content: space-around; /* Center-align items horizontally */
    align-items: center; /* Add this line */
    flex-direction: row;
  }
}
.sub-menu-item {
  width: 100%;
  padding: 2px 13px;
  text-align: center;
}
.sub-menu-item:hover,
.sub-menu-item:active {
  background-color: black;
  color: #d1d75d !important;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  padding: 2px 13px;
}

.sub-menu-item.active {
  border-radius: 8px;
  background-color: black;
  color: #d1d75d;
  padding: 2px 13px;
}
.app_link {
  color: white !important;
  padding: 2px 13px;

  text-align: left;
}
.app_submenu_block li {
  color: white !important;

  text-align: left;
}
.app_submenu_block a {
  color: white !important;
  text-align: left;
}
.app_submenu_block li:hover {
  border-radius: 8px;
  text-align: left;

  background-color: black !important;
}
.app_submenu_block li:hover a {
  color: #dedf1b !important;
  text-align: left;
}
.sub-menu-item_a {
  color: white;
  font-size: 15px;
}
.sub-menu-item_a:active {
  color: #d1d75d !important;
  text-align: center;
  width: 100%;
  font-size: 15px;
}

.sub-menu-item_a:hover {
  font-size: 15px;

  color: #d1d75d !important;
  text-align: center;
  width: 100%;
}

.sub-menu-item:hover {
  background-color: #131313;
}

.connectWallet {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem; /* Typical value for gap-2 */
  padding: 0.5rem 3rem; /* px-4 */
  background-color: #1c1c1c; /* bg-vgray, you need to define --vgray in your CSS */
  border: 1px solid #dedf1b !important; /* border-[1px] and border-vyellow */
  border-radius: 0.5rem !important; /* rounded-lg, typically large border-radius like 0.5rem */
  color: white; /* text-white */
}

@media (max-width: 992px) {
  /* Assuming 992px is your 'lg' breakpoint */
  .connectWallet {
    height: 2.5rem; /* max-lg:h-10, assuming h-10 translates to 2.5rem */
    padding-top: 0; /* max-lg:py-0 */
    padding-bottom: 0; /* max-lg:py-0 */
    align-items: center;
  }
}
@media (max-width: 992px) {
  /* Assuming 992px is your 'lg' breakpoint */

  .connectWalletdiv {
    padding: 1%;
  }
}
@media all and (min-width: 769px) {
  .my_heading {
    font-size: 2rem;
    z-index: 10;
    font-weight: 500;

    color: white;
  }
}
@media all and (max-width: 768px) {
  .my_heading {
    text-align: left;
    font-size: 1rem;
    z-index: 10;
    position: relative;
    font-weight: 500;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin: 0rem 0rem 0rem 0;
  }
}
.main_body {
  max-width: 1400px;
  height: 100vh;
  margin: 0 auto;
  overflow-y: auto !important;
}
@media all and (max-width: 768px) {
  .main_body {
    margin: 0 auto;
    height: 100%;
    overflow-y: auto !important;

    padding: 0 10px;
  }
}
